import { defineStore } from "pinia";
import { randomString } from "@/utils";
import * as lsStore from "@/utils/ls-store";
import { getUrlParams } from "@/utils";

interface IUserState {
  token: string;
  uuid: string;
  query: {
    [prop: string]: string;
  };
}

export const useUserStore = defineStore({
  id: "user",
  state(): IUserState {
    return {
      token: "",
      uuid: "",
      query: {}
    };
  },
  actions: {
    init() {
      if (!this.uuid) {
        const uuid = randomString(16) + +new Date();
        this.uuid = uuid;
      }

      this.query = getUrlParams();
    }
  },
  persist: {
    // 持久化 & 加密
    storage: lsStore,
    paths: ["token", "uuid"]
  }
});
