import SecureLs from "secure-ls";

const secretKey = "8SClvQlN0BFWmkTP";

const ls = new SecureLs({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: secretKey
});

export const getItem = (key: string): string | null => ls.get(key);
export const setItem = (key: string, value: string) => ls.set(key, value);
export const removeItem = (key: string) => ls.remove(key);
export const clear = () => ls.removeAll();
