export const randomString = (len: number) => {
  len = len || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (let i = 0; i < len; i++) {
    let index = Math.floor(Math.random() * a);
    let str = t.substr(index, 1);
    n += str;
  }
  return n;
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getUrlParams = function (url?: string) {
  url = url || window.location.toString();
  let paramsStr = url.split("?")[1];

  if (!paramsStr) return {};

  if (paramsStr.indexOf("#") >= 0) {
    paramsStr = paramsStr.split("#")[0];
  }
  if (!paramsStr) return {};

  return paramsStr
    .split("&")
    .map((item) => item.split("="))
    .reduce((prev, [key, val]) => ((prev[key] = val), prev), {} as { [key: string]: any });
};

export const formatNumber = function (numStr: number | string) {
  if (!numStr) {
    return 0;
  }
  numStr = `${numStr}`;
  let num = parseInt(numStr);
  if (numStr.length < 5) {
    return num;
  } else if (numStr.length < 11) {
    return (((num / 10000) * 10) / 10).toFixed(1) + "w";
  } else {
    return 0;
  }
};

export const numfix = (num: number | string, fixed: number = 2) => {
  const formatNum = Number(num);

  if (isNaN(formatNum)) return num;

  return formatNum / 10000 >= 1
    ? Number((formatNum / 10000).toFixed(fixed)) + "w"
    : formatNum / 1000 >= 1
    ? Number((formatNum / 1000).toFixed(fixed)) + "k"
    : num;
};

// 下载图片

export const downloadIamge = (imgsrc: string, name: string) => {
  return new Promise((resolve, reject) => {
    try {
      //下载图片地址和图片名
      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context!.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件

        resolve(true);
      };

      image.src = `${imgsrc}?tamp=${+new Date().valueOf()}`;
    } catch (error) {
      reject(error);
    }
  });
};
